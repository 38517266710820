import React from "react"
import {
  generateFacebookShareUrl,
  generateLinkedInShareUrl,
  generateTwitterShareUrl,
} from "../../utils/socialUtils"
import "./style.css"

const ProjectTemplate: React.FC<{
  html: any
  frontmatter: any
  siteUrl: string
  slug: string
}> = ({ html, frontmatter, siteUrl, slug }) => {
  const hasSource = frontmatter.source && frontmatter.source !== 'none';
  const hasDemo = frontmatter.demo && frontmatter.demo !== 'none';

  return (
    <section id="project" className="project-page">
      <div className="container">
        {frontmatter.postImage && (
          <div
            className="project-hero"
            style={{ backgroundImage: `url(${frontmatter.postImage})` }}
          ></div>
        )}
        <header className="project-header">
          <h1>{frontmatter.title}</h1>
          <div className="social-links mt-3">
            <a
              href={generateTwitterShareUrl(siteUrl, slug, frontmatter.title)}
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
              title="Share on Twitter"
            >
              <i className="bx bxl-twitter"></i>
            </a>
            <a
              href={generateLinkedInShareUrl(siteUrl, slug, frontmatter.title)}
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
              title="Share on LinkedIn"
            >
              <i className="bx bxl-linkedin"></i>
            </a>

            <a
              href={generateFacebookShareUrl(siteUrl, slug)}
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
              title="Share on Facebook"
            >
              <i className="bx bxl-facebook"></i>
            </a>
          </div>
          <div className="project-link">
            {hasSource && (
              <a
                href={frontmatter.source}
                target="_blank"
                rel="noopener noreferrer"
                title="Source Code"
                className="mr-4"
              >
                <i className="bx bxl-github"></i> Source
              </a>
            )}
            {hasDemo && (
              <a
                href={frontmatter.demo}
                target="_blank"
                rel="noopener noreferrer"
                title="Demo"
                className="mr-4"
              >
                <i className="bx bx-link-external"></i> Website
              </a>
            )}
          </div>
        </header>
        <section
          className="project-body"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {frontmatter.tags && (
          <section className="project-tags">
            {frontmatter.tags.map((tag: string) => (
              <p className="tag" key={tag}>
                {tag}
              </p>
            ))}
          </section>
        )}
      </div>
    </section>
  )
}

export default ProjectTemplate
